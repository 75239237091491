'use strict';
// This module should contain "global" code, like jQuery plugin calls.
// Code placed here is different than the code placed in the shame.js module,
// since although the code written here is not modular, it is not shameful
// either. It's just the way it works.
module.exports = {
    maxHeight: 0,

    init: function() {
        var self = this;
        if (document.querySelector('.js-equalizer') == null) {
            return;
        }

        Array.prototype.forEach.call(
            document.querySelectorAll('.js-equalizer'),
            function(el, i) {
                if (self.maxHeight < el.offsetHeight) {
                    self.maxHeight = el.offsetHeight;
                }
            }
        );

        this.setHeight();

        window.addEventListener('resize', this.setHeight.bind(this));
    },

    setHeight: function() {
        var self = this;

        Array.prototype.forEach.call(
            document.querySelectorAll('.js-equalizer'),
            function(el, i) {
                if (window.innerWidth < 705) {
                    el.style.height = '';

                    return;
                }

                el.style.height = self.maxHeight + 'px';
            }
        );
    }
};
